import React, {useEffect} from 'react'

const Webinar = () => {
    useEffect(()=>{
        window.location.href = "https://attendee.gotowebinar.com/register/4074920244785733390"
    }, [])
    return (
        <div></div>
    )
}

export default Webinar